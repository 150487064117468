<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Toast />
        <h2>Producto</h2>
        <BasicFormToolbar
        @new="openNew" @refresh="refresh" @export="exportCSV"
        :actions="['new','refresh','export']" />
        <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" 
        :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />
        <Dialog
          v-model:visible="newDialog"
          :style="{ width: '450px' }"
          :header="!entity.id ? 'Nueva Producto' : 'Editar Producto'"
          :modal="true"
          class="p-fluid"
        >
          
          <FormInputText wrapperClass="field" label="SKU" v-model="entity.sku"
          :valid="validate.validations.sku"/>
          <FormInputText wrapperClass="field" label="Nombre" v-model="entity.name"
          :valid="validate.validations.name"/>
          <FormInputNumber wrapperClass="field" label="Precio Unitario" v-model="entity.unit_price"
          :valid="validate.validations.unit_price" :minFractionDigits="2" :maxFractionDigits="2" :mode="'decimal'"/>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              :loading="loading"
              @click="save"
            />
          </template>
        </Dialog>
        <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../../utilities/General";
import Loader from "../../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../../components/general/BasicFormToolbar.vue";
import BasicDatatable from "../../../../components/general/BasicDatatable.vue";
import DeleteDialog from "../../../../components/general/DeleteDialog.vue";
import FormInputText from "../../../../components/general/FormInputText.vue";
import FormInputNumber from "../../../../components/general/FormInputNumber.vue";
import Session from "../../../../mixins/sessionMixin";
import { BWProduct } from '../../../../models/custom/business_wallet/BWProduct';
export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      entities: [],
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "sku" }),
        new Rule({ name: "unit_price" })
      ],
      validate: {
        valid: false,
        validations: {
          name: null,
          sku: null,
          unit_price: null
        },
      },
      headers: [
        new HeaderGrid("SKU", "sku"),
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("P.U.", "unit_price", {type: "currency"}),
      ],
      loading: false,
    };
  },
  components: { Loader, FormInputNumber,BasicFormToolbar, BasicDatatable, DeleteDialog, FormInputText},
  created() {
    this.entity = new BWProduct(this.session);
    this.initFilters();
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    deleted() {
      this.deleteEntity();
    },
    openNew() {
      this.entity = new BWProduct(this.session);
      this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra

          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new BWProduct(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new BWProduct(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>


<style>

</style>