import {
    Abstract
} from './Abstract';

export class BWProduct extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Tokenization/Product', session);
        this.sku = null;
        this.name = null;
        this.unit_price = null;
    }
}